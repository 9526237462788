<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <b-overlay :show="mortgageList.length === 0" rounded="sm">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Payment Links</h4>
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-6">
                                    <div
                                        id="tickets-table_length"
                                        class="dataTables_length"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Show&nbsp;
                                            <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                            ></b-form-select
                                            >&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Search:
                                            <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                            ></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive mb-0">
                                <b-table
                                    :items="mortgageList"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    sort-icon-left
                                    :filter-included-fields="filterOn"
                                >
                                    <template
                                        v-slot:cell(payment_status)="data"
                                    >
                                        <p
                                            :class="
                                                data.item.payment_status ===
                                                'Paid'
                                                    ? 'paid'
                                                    : 'pending'
                                            "
                                        >
                                            {{ data.item.payment_status }}
                                        </p>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button
                                            variant="primary"
                                            @click="view(data)"
                                            >Details</b-button
                                        >
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </b-overlay>
                <div class="row float-right">
                    <i class="ri-arrow-left-line arrow" @click="previous"></i>
                    &nbsp;&nbsp;&nbsp;
                    <i class="ri-arrow-right-line arrow" @click="next"></i>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import { REQUEST_STATUS } from "../../common";

export default {
    components: {
        Layout,
    },
    mounted() {
        if (
            localStorage.cs_user_role === "Administrator" ||
            localStorage.cs_user_role === "Archiving" ||
            localStorage.cs_user_role === "Customer Service" ||
            localStorage.cs_user_role === "Collection"
        ) {
            this.getMortgageList();
        } else {
            this.$router.push({
                path: "/",
            });
        }
    },
    data() {
        return {
            isBusy: true,
            show: true,
            stats: REQUEST_STATUS,
            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            pageOptions: [10, 25, 50],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            offset: 0,
            fields: [
                { key: "id", sortable: true },
                { key: "noc_type" },
                { key: "unit" },
                { key: "first_name" },
                { key: "email" },
                { key: "amount" },
                { key: "payment_status", sortable: true },
                { key: "created_by" },
                { key: "created_at", sortable: true },
                "Action",
            ],
        };
    },
    methods: {
        view(data) {
            console.log(data.item);
            let payload = data.item;
            this.$router.replace({
                name: "paymentLinkDetails",
                params: { payload },
            });
            // this.$router.push({
            //     path: `/payment-link/${data.item.id}`,
            // });
        },

        getMortgageList() {
            this.$store
                .dispatch("apidata/fetchPaymentLinks", this.offset)
                .then(() => {
                    this.isBusy = false;
                });
        },
        previous() {
            if (this.offset == 0) {
                return;
            }
            this.offset = this.offset - 50;
            this.isBusy = true;
            this.getMortgageList();
        },
        next() {
            this.isBusy = true;
            this.offset = this.offset + 50;
            this.getMortgageList();
        },
    },
    //
    computed: {
        mortgageList() {
            return this.$store.getters["apidata/getPaymentLinks"];
        },
        rows() {
            return this.mortgageList.length;
        },
    },
};
</script>
<style scoped>
.arrow {
    font-size: 20px;
    font-weight: bolder;
}
.arrow:hover {
    text-align: center;
    cursor: pointer;
    background-color: #c59220;
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
.paid {
    font-weight: bolder;
    color: green;
}
.pending {
    font-weight: bolder;
    color: red;
}
</style>
